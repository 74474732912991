import Styles from './styles.module.css';
import React, { useState } from 'react'
import { v4 as uuid } from 'uuid';
import firebase from 'firebase/compat/app'
import { useHistory } from 'react-router-dom';

const Footer = () => {

  function addCompany() {
    var docId = uuid();

    firebase.firestore().collection('companies').doc(docId)
    .set({
      member_id: 'Fi-ne',
      id: docId,
      name: 'Fi-ne',
      logo : false,
      owner: [ 'j4MAUcj1KSM0hgamrYODkXTuUNc2' ],
      administrator: [ 'j4MAUcj1KSM0hgamrYODkXTuUNc2' ],
      member: [ 'j4MAUcj1KSM0hgamrYODkXTuUNc2' ],
      invite: [ 'finejinji0401@gmail.com' ],
      recruits: [],
      stores: [],
      time: new Date(),
      recruitLimit: 0,
    })
    // info@buzontokyo.con
    .then(() => {
      // history.push(`/service/id=${docId}+page=1`);
      console.log('done');
    })

    // firebase.firestore().collection('recruits').get()
    // .then(snapShot => {
    //   snapShot.forEach((doc) => {
    //     var name = doc.data().store_name === undefined ? '' : doc.data().store_name;
    //     var tmp = name.split('');
    //     var tags = doc.data().recruit_searchTags === undefined ? [ '' ] : Object.keys(doc.data().recruit_searchTags);


    //     var array = [ ...tags, ...tmp ];
    //     console.log(array);

    //     // if (name === undefined) {
    //     //   console.log(doc.id)
    //     // }

    //     // console.log(tags);

    //     // console.log(doc.id + '：' + tags);
    //     // for (var index = 0; index < tags.length; index++) {
    //     // }

    //     if (doc.id === '5a5cf989-c62e-4e17-88a3-2f783a34e44d') {
    //       console.log(Object.keys(tags));

    //       // firebase.firestore().collection('recruits').doc(doc.id)
    //       // .update({
    //       //   recruit_searchTags : [ ...tags, ...tmp, ]
    //       // })
    //     }

    //   });
    // });

  }
  
  return (
    <>
    <button onClick={() => addCompany()}>会社追加</button>
    </>
  );
}

export default Footer;
