import { Splide, SplideSlide } from "@splidejs/react-splide";
import Styles from "./styles.module.css";
import PentagonTreatment from "./../pentagonTreatment/index";
import PentagonStyle from "./../pentagonStyle/index";
import '@splidejs/splide/css';
import React, { useState } from 'react';

const Slider = (props) => {

  const [ pentagonSwitch, setPentagonSwitch ] = useState(true);

  console.log(props.storeList);

  return (
    <>
    <Splide
      aria-label=""
      options={{
        perPage: 1,
        autoplay: true,
        interval: 3000,
      }}
    >
      {props.storeList.map((data, index) =>
        <SplideSlide>
          <div className={Styles.store} key={index}>
            <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/stores%2Fresize_images%2F${data['image']}_1080x1080?alt=media&token=`} />
            <h4>{data.name}</h4>
            <h5>{data.address}</h5>
            <h6>{`${data['stationCount'] >= 1 ? data['station1Name'] : data['station1Name']} ${data['stationCount'] >= 1 ? data['station1Time'] : data['station1Time']} ${data['stationCount'] >= 2 ? '、' : ''} ${data['stationCount'] >= 2 ? data['station2Name'] : data['station2Name']} ${data['stationCount'] >= 2 ? data['station2Time'] : data['station2Time']} ${data['stationCount'] >= 3 ? '、' : ''} ${data['stationCount'] >= 3 ? data['station3Name'] : data['station3Name']} ${data['stationCount'] >= 3 ? data['station3Time'] : data['station3Time']}`}</h6>
            
            <div className={Styles.title}>
              <h4 className={Styles.title_bar} onClick={() => setPentagonSwitch(true)} style={pentagonSwitch ? {} : {backgroundColor : '#ffffff', color: '#AD0200', borderBottom : '1px solid #AD0200',}}>
                施術頻度
              </h4>
              <h4 className={Styles.title_bar} onClick={() => setPentagonSwitch(false)} style={pentagonSwitch ? {backgroundColor : '#ffffff', color: '#AD0200', borderBottom : '1px solid #AD0200',} : {}}>
                スタイル頻度
              </h4>
            </div>

            {pentagonSwitch ?
              <PentagonTreatment
                pentagonTreatment={[
                  data.mood1 === undefined ? 1 : data.mood1,
                  data.mood2 === undefined ? 1 : data.mood2,
                  data.mood3 === undefined ? 1 : data.mood3,
                  data.mood4 === undefined ? 1 : data.mood4,
                  data.mood5 === undefined ? 1 : data.mood5,
                  data.mood6 === undefined ? 1 : data.mood6,
                ]}
              />
              :
              <PentagonStyle
                pentagonStyle={[
                  data.style1 === undefined ? 1 : data.style1,
                  data.style2 === undefined ? 1 : data.style2,
                  data.style3 === undefined ? 1 : data.style3,
                  data.style4 === undefined ? 1 : data.style4,
                  data.style5 === undefined ? 1 : data.style5,
                  data.style6 === undefined ? 1 : data.style6,
                ]}

                triangleTreatTitle={data.pentagonStyleInput === undefined ? '' : data.pentagonStyleInput}
              />
            }
          </div>
        </SplideSlide>
      )}
    </Splide>
    </>
  );
}

export default Slider;
