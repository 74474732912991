import DocumentMeta from 'react-document-meta';
import React, { useState, useLayoutEffect } from 'react';
import firebase from 'firebase/compat/app';
import Nav from './../header/index';
import { useHistory } from 'react-router-dom';
import Search from '../search/index';
import Styles from './styles.module.css';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Registration from './../registration/index';
import Slider from "./strongImage/index";
import Header from "./header/index";
import Point from "./point/index";
import Pentagon from "./pentagonTreatment/index";
import Person from "./person/index";
import Employment from "./employment/index";
import Number from "./number/index";
import Posts from "./posts/index";
import Interview from "./interviews/index";
import Stores from "./stores/index";

const Footer = () => {


  const history = useHistory();  
  
  const [ user, setUser ] = useState(false);

  const [ companyId, setCompanyId ] = useState('');
  
  const [ recruitList, setRecruitList ] = useState([]);
  const [ storeList, setStoreList ] = useState([]);

  const [ modal, setModal ] = useState(false);

  var link = new URL(window.location).href;
  var recruit = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.length));

  const [ image1Title, setImage1Title ] = useState('');
  const [ image1Desc, setImage1Desc ] = useState('');

  const [ image2Title, setImage2Title ] = useState('');
  const [ image2Desc, setImage2Desc ] = useState('');

  const [ image3Title, setImage3Title ] = useState('');
  const [ image3Desc, setImage3Desc ] = useState('');

  const [ interview1Name, setInterview1Name ] = useState('');
  const [ interview1School, setInterview1School ] = useState('');
  const [ interview1Title, setInterview1Title ] = useState('');
  const [ interview1Desc, setInterview1Desc ] = useState('');

  const [ interview2Name, setInterview2Name ] = useState('');
  const [ interview2School, setInterview2School ] = useState('');
  const [ interview2Title, setInterview2Title ] = useState('');
  const [ interview2Desc, setInterview2Desc ] = useState('');

  const [ interview3Name, setInterview3Name ] = useState('');
  const [ interview3School, setInterview3School ] = useState('');
  const [ interview3Title, setInterview3Title ] = useState('');
  const [ interview3Desc, setInterview3Desc ] = useState('');

  const [ interview4Name, setInterview4Name ] = useState('');
  const [ interview4School, setInterview4School ] = useState('');
  const [ interview4Title, setInterview4Title ] = useState('');
  const [ interview4Desc, setInterview4Desc ] = useState('');

  const [ interview5Name, setInterview5Name ] = useState('');
  const [ interview5School, setInterview5School ] = useState('');
  const [ interview5Title, setInterview5Title ] = useState('');
  const [ interview5Desc, setInterview5Desc ] = useState('');

  const [ interview6Name, setInterview6Name ] = useState('');
  const [ interview6School, setInterview6School ] = useState('');
  const [ interview6Title, setInterview6Title ] = useState('');
  const [ interview6Desc, setInterview6Desc ] = useState('');

  const [ storeNumberTags, setStoreNumberTags ] = useState([]);
  const [ storeNumber1, setStoreNumber1 ] = useState(0);
  const [ storeNumber2, setStoreNumber2 ] = useState(0);
  const [ storeNumber3, setStoreNumber3 ] = useState(0);
  const [ storeNumber4, setStoreNumber4 ] = useState(0);
  const [ storeNumber5, setStoreNumber5 ] = useState(0);
  const [ storeNumber6, setStoreNumber6 ] = useState(0);
  const [ storeNumber7, setStoreNumber7 ] = useState(0);
  const [ storeNumber8, setStoreNumber8 ] = useState(0);
  const [ storeNumber9, setStoreNumber9 ] = useState(0);
  const [ storeNumber10, setStoreNumber10 ] = useState(0);
  const [ storeNumber11, setStoreNumber11 ] = useState(0);
  const [ storeNumber12, setStoreNumber12 ] = useState(0);
  const [ storeNumber13, setStoreNumber13 ] = useState(0);

  const [ image1FilesName, setImage1FilesName ] = useState([]);
  const [ image2FilesName, setImage2FilesName ] = useState([]);
  const [ image3FilesName, setImage3FilesName ] = useState([]);

  const [  interview, setInterview ] = useState(0);

  useLayoutEffect(() => {
    getData();
    update();
    store(recruit);
  }, [recruit]);

  function getData() {
    firebase.firestore().collection('recruits').doc(recruit).get()
    .then((doc) => {
      setRecruitList({...doc.data(), id: doc.id });
      setCompanyId(doc.data().companyId);

      setImage1Title(doc.data().recruit_image1Title === undefined ? [] : doc.data().recruit_image1Title);
      setImage1Desc(doc.data().recruit_image1Desc === undefined ? [] : doc.data().recruit_image1Desc);

      setImage2Title(doc.data().recruit_image2Title === undefined ? [] : doc.data().recruit_image2Title);
      setImage2Desc(doc.data().recruit_image2Desc === undefined ? [] : doc.data().recruit_image2Desc);

      setImage3Title(doc.data().recruit_image3Title === undefined ? [] : doc.data().recruit_image3Title);
      setImage3Desc(doc.data().recruit_image3Desc === undefined ? [] : doc.data().recruit_image3Desc);

      setInterview1Title(doc.data().recruit_interview1Title === undefined ? [] : doc.data().recruit_interview1Title);
      setInterview1Desc(doc.data().recruit_interview1Desc === undefined ? [] : doc.data().recruit_interview1Desc);

      setInterview2Title(doc.data().recruit_interview2Title === undefined ? [] : doc.data().recruit_interview2Title);
      setInterview2Desc(doc.data().recruit_interview2Desc === undefined ? [] : doc.data().recruit_interview2Desc);

      setInterview3Title(doc.data().recruit_interview3Title === undefined ? [] : doc.data().recruit_interview3Title);
      setInterview3Desc(doc.data().recruit_interview3Desc === undefined ? [] : doc.data().recruit_interview3Desc);

      setStoreNumberTags(doc.data().recruit_storeNumberTags === undefined ? [] : doc.data().recruit_storeNumberTags);
      setStoreNumber1(doc.data().recruit_storeNumber1 === undefined ? 0 : doc.data().recruit_storeNumber1);
      setStoreNumber2(doc.data().recruit_storeNumber2 === undefined ? 0 : doc.data().recruit_storeNumber2);
      setStoreNumber3(doc.data().recruit_storeNumber3 === undefined ? 0 : doc.data().recruit_storeNumber3);
      setStoreNumber4(doc.data().recruit_storeNumber4 === undefined ? 0 : doc.data().recruit_storeNumber4);
      setStoreNumber5(doc.data().recruit_storeNumber5 === undefined ? 0 : doc.data().recruit_storeNumber5);
      setStoreNumber6(doc.data().recruit_storeNumber6 === undefined ? 0 : doc.data().recruit_storeNumber6);
      setStoreNumber7(doc.data().recruit_storeNumber7 === undefined ? 0 : doc.data().recruit_storeNumber7);
      setStoreNumber8(doc.data().recruit_storeNumber8 === undefined ? 0 : doc.data().recruit_storeNumber8);
      setStoreNumber9(doc.data().recruit_storeNumber9 === undefined ? 0 : doc.data().recruit_storeNumber9);
      setStoreNumber10(doc.data().recruit_storeNumber10 === undefined ? 0 : doc.data().recruit_storeNumber10);
      setStoreNumber11(doc.data().recruit_storeNumber11 === undefined ? 0 : doc.data().recruit_storeNumber11);
      setStoreNumber12(doc.data().recruit_storeNumber12 === undefined ? 0 : doc.data().recruit_storeNumber12);
      setStoreNumber13(doc.data().recruit_storeNumber13 === undefined ? 0 : doc.data().recruit_storeNumber13);

      setImage1FilesName(doc.data().recruit_image1FilesName === undefined ? 0 : doc.data().recruit_image1FilesName);
      setImage2FilesName(doc.data().recruit_image2FilesName === undefined ? 0 : doc.data().recruit_image2FilesName);
      setImage3FilesName(doc.data().recruit_image3FilesName === undefined ? 0 : doc.data().recruit_image3FilesName);
      
      setInterview(doc.data().recruit_interviewCount === undefined ? 0 : doc.data().recruit_interviewCount);

      if (1 <= doc.data().recruit_interviewCount) {
        setInterview1Name(doc.data().recruit_interview1Name);
        setInterview1School(doc.data().recruit_interview1School);
        setInterview1Title(doc.data().recruit_interview1Title);
        setInterview1Desc(doc.data().recruit_interview1Desc);
      }

      if (2 <= doc.data().recruit_interviewCount) {
        setInterview2Name(doc.data().recruit_interview2Name);
        setInterview2School(doc.data().recruit_interview2School);
        setInterview2Title(doc.data().recruit_interview2Title);
        setInterview2Desc(doc.data().recruit_interview2Desc);
      }

      if (3 <= doc.data().recruit_interviewCount) {
        setInterview3Name(doc.data().recruit_interview3Name);
        setInterview3School(doc.data().recruit_interview3School);
        setInterview3Title(doc.data().recruit_interview3Title);
        setInterview3Desc(doc.data().recruit_interview3Desc);
      }

      if (4 <= doc.data().recruit_interviewCount) {
        setInterview4Name(doc.data().recruit_interview4Name);
        setInterview4School(doc.data().recruit_interview4School);
        setInterview4Title(doc.data().recruit_interview4Title);
        setInterview4Desc(doc.data().recruit_interview4Desc);
      }

      if (5 <= doc.data().recruit_interviewCount) {
        setInterview5Name(doc.data().recruit_interview5Name);
        setInterview5School(doc.data().recruit_interview5School);
        setInterview5Title(doc.data().recruit_interview5Title);
        setInterview5Desc(doc.data().recruit_interview5Desc);
      }

      if (6 <= doc.data().recruit_interviewCount) {
        setInterview6Name(doc.data().recruit_interview6Name);
        setInterview6School(doc.data().recruit_interview6School);
        setInterview6Title(doc.data().recruit_interview6Title);
        setInterview6Desc(doc.data().recruit_interview6Desc);
      }

    });
  }

  function store(recruit) {

    firebase.firestore().collection('stores').where('recruitId', '==', recruit).orderBy("number", "asc").get()
    .then(snapShot => {
      var tmp = [];
      snapShot.forEach((doc) => {
        tmp.push({
          'index' : tmp.length,

          'name' : doc.data().name,
          'address' : doc.data().address,
          'image' : doc.data().image,

          'stationCount' : doc.data().stationCount,
          'station1Name' : doc.data().station1Name,
          'station1Time' : doc.data().station1Time,
          'station2Name' : doc.data().station2Name,
          'station2Time' : doc.data().station2Time,
          'station3Name' : doc.data().station3Name,
          'station3Time' : doc.data().station3Time,

          'type' : doc.data().type,

          'mood1' : doc.data().pentagonTreatment1,
          'mood2' : doc.data().pentagonTreatment2,
          'mood3' : doc.data().pentagonTreatment3,
          'mood4' : doc.data().pentagonTreatment4,
          'mood5' : doc.data().pentagonTreatment5,
          'mood6' : doc.data().pentagonTreatment6,

          'style1' : doc.data().pentagonStyle6,
          'style2' : doc.data().pentagonStyle1,
          'style3' : doc.data().pentagonStyle2,
          'style4' : doc.data().pentagonStyle3,
          'style5' : doc.data().pentagonStyle4,
          'style6' : doc.data().pentagonStyle5,

          'pentagonStyleInput' : doc.data().pentagonStyleInput,

          // "count" : count,
        });
      });
      setStoreList(tmp);
    });
  }

  function update() {
    return onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        firebase.firestore().collection('recruits').doc(recruit)
          .update({ data_pv: firebase.firestore.FieldValue.arrayUnion(new Date()), });
        setUser(true);
      } else {
        firebase.firestore().collection('recruits').doc(recruit)
          .update({ data_pv: firebase.firestore.FieldValue.arrayUnion(new Date()), });
      }
    })
  }

  function apply() {
    if (user) {
      history.push(`/applicants/id=${companyId}+page=1+recruitId=${recruit}`)
    } else {
      setModal(true);
    }
  }

  const meta = {
    title: `${recruitList.store_name === undefined ? '' : recruitList.store_name} | memorii works`,
    description: '美容学生と就活と美容室様の新卒採用を解決するための採用プラットフォーム。',
    canonical: 'https://memorii-works.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>

      <Nav />
      
      {recruitList.length !== 0 ?
        <div className={Styles.recruit}>
          <Header 
            recruitList={recruitList}
            image={recruitList.store_imageName === undefined ? [] : recruitList.store_imageName} 
          />

          <Point
            name={recruitList.store_name === undefined ? [] : recruitList.store_name}
            point1={recruitList.recruit_point1Title === undefined ? [] : recruitList.recruit_point1Title}
            point2={recruitList.recruit_point2Title === undefined ? [] : recruitList.recruit_point2Title}
            point3={recruitList.recruit_point3Title === undefined ? [] : recruitList.recruit_point3Title}
          />

          {storeList.length !== 0 ?
          <>
            <Stores storeList={storeList} />
          </> : <></> }

          <Number 
            storeNumberTags={storeNumberTags}
            storeNumber1={storeNumber1}
            storeNumber2={storeNumber2}
            storeNumber3={storeNumber3}
            storeNumber4={storeNumber4}
            storeNumber5={storeNumber5}
            storeNumber6={storeNumber6}
            storeNumber7={storeNumber7}
            storeNumber8={storeNumber8}
            storeNumber9={storeNumber9}
            storeNumber10={storeNumber10}
            storeNumber11={storeNumber11}
            storeNumber12={storeNumber12}
            storeNumber13={storeNumber13}
          />

          <div className={Styles.text}>
            <h3>写真で強みを知る</h3>

            {1 <= recruitList.recruit_imageCount ?
              <div className={Styles.image_appeal}>
                <Slider imageName={image1FilesName} number={1} />
                <h4>{image1Title}</h4>
                <p>{image1Desc}</p>
              </div>
            : <></> }

            {2 <= recruitList.recruit_imageCount ?
              <div className={Styles.image_appeal}>
              <Slider imageName={image2FilesName} number={2} />
                <h4>{image2Title}</h4>
                <p>{image2Desc}</p>
              </div>
            : <></> }

            {3 <= recruitList.recruit_imageCount ?
              <div className={Styles.image_appeal}>
              <Slider imageName={image3FilesName} number={3} />
                <h4>{image3Title}</h4>
                <p>{image3Desc}</p>
              </div>
            : <></> }
          </div>

          <div className={Styles.text}>
            {/* <h3>先輩の声</h3> */}

            <Interview
              count={interview} recruit={recruit}
              name1={interview1Name} school1={interview1School} title1={interview1Title} desc1={interview1Desc}
              name2={interview2Name} school2={interview2School} title2={interview2Title} desc2={interview2Desc}
              name3={interview3Name} school3={interview3School} title3={interview3Title} desc3={interview3Desc}
              name4={interview4Name} school4={interview4School} title4={interview4Title} desc4={interview4Desc}
              name5={interview5Name} school5={interview5School} title5={interview5Title} desc5={interview5Desc}
              name6={interview6Name} school6={interview6School} title6={interview6Title} desc6={interview6Desc}
            />

          </div>

          <Person 
            bar1={recruitList.recruit_person1 === undefined ? 1 : recruitList.recruit_person1}
            bar2={recruitList.recruit_person2 === undefined ? 1 : recruitList.recruit_person2}
            bar3={recruitList.recruit_person3 === undefined ? 1 : recruitList.recruit_person3}
            bar4={recruitList.recruit_person4 === undefined ? 1 : recruitList.recruit_person4}
            bar5={recruitList.recruit_person5 === undefined ? 1 : recruitList.recruit_person5}
          />

          <Employment
            recruitList={recruitList}
          />

          <Posts 
            companyId={companyId}
          />

        </div>
      : <></> }

      {modal ?
        <Registration setModal={setModal} />
      : <></> }

    </DocumentMeta>
    </>
  );
}

export default Footer;
